export default (num: number): string => {
  let number = num + 1;
  let ret = '';
  let a = 1;
  let b = 26;

  for (; (number -= a) >= 0; a = b, b *= 26) {
    ret = String.fromCharCode(Math.floor((number % b) / a) + 65) + ret;
  }
  return ret;
}
