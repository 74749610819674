import * as React                 from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { updateTemplate }         from "src/redux/actions";
import styles                     from './TemplateName.scss';
import { ReduxState }             from "src/redux/emptyState";

type OwnProps = {
  templateId: number,
};
type StoreProps = ReturnType<typeof mapStateToProps>;
type StoreDispatch = typeof mapDispatchToProps;
type Props = OwnProps & StoreProps & ResolveThunks<StoreDispatch>;
type State = {
  edit: boolean,
}

class TemplateName extends React.PureComponent<Props, State> {
  state: State = {
    edit: false,
  };

  onEdit = () => this.setState({ edit: true });

  onSave = (e: React.FormEvent<HTMLFormElement>) => {
    const { templateId, updateTemplate } = this.props;
    e.preventDefault();
    e.stopPropagation();
    const name = (e.target as any).name.value as string;
    updateTemplate(templateId, { name }).then(this.onCancel);
  };

  onCancel = () => this.setState({ edit: false });

  renderStatic() {
    const { template } = this.props;

    return (
      <React.Fragment>
        {template.name}{' '}
        <button className="btn btn-link btn-sm" onClick={this.onEdit}>
          <i className="far fa-edit" />
        </button>
      </React.Fragment>
    );
  }

  renderEdit() {
    const { template } = this.props;

    return (
      <form onSubmit={this.onSave}>
        <div className="input-group">
          <input size={30} className="form-control form-control-sm" name="name" defaultValue={template.name} />
          <div className="input-group-append">
            <button className="btn btn-sm btn-outline-success" type="submit">
              <i className="far fa-save" />
            </button>
            <button className="btn btn-sm btn-outline-danger" type="button" onClick={this.onCancel}>
              <i className="fas fa-times" />
            </button>
          </div>
        </div>
      </form>
    );
  }

  render() {
    const { edit } = this.state;
    const { template } = this.props;
    if (!template) return null;

    return (
      <div className={styles.templateName}>
        {edit ? this.renderEdit() : this.renderStatic()}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState, { templateId }: OwnProps) => ({
  template: state.templatesDict && state.templatesDict[templateId],
});
const mapDispatchToProps = { updateTemplate };
export default connect<StoreProps, StoreDispatch, OwnProps>(mapStateToProps, mapDispatchToProps)(TemplateName);
