import { AnyAction, applyMiddleware } from 'redux';
import thunk, { ThunkMiddleware }     from 'redux-thunk';
import { ReduxState }                 from "src/redux/emptyState";
// import setCookie           from './setCookie';
// import messageBroadcast    from './messageBroadcast';

export default applyMiddleware(
  thunk as ThunkMiddleware<ReduxState, AnyAction>,
  // setCookie,
  // messageBroadcast,
);
