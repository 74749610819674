import { AnyAction }  from 'redux';
import * as c         from 'src/redux/constants';
import { ReduxState } from "src/redux/emptyState";

type Reducer = { [actionName: string]: (state: ReduxState, action: AnyAction) => ReduxState };

const reducers: Reducer[] = [];

export const addReducer = (reducer: Reducer) => {
  if (!reducers.includes(reducer)) reducers.push(reducer);
};

addReducer({
  [c.UPDATE_FILES_LIST]: (state, { files, filesDict }) => ({
    ...state,
    files,
    filesDict,
  }),

  [c.UPDATE_TEMPLATES_LIST]: (state, { templates, templatesDict }) => ({
    ...state,
    templates,
    templatesDict,
  }),

  [c.SELECT_TEMPLATE]: (state, { templateId }) => ({
    ...state,
    selectedTemplate: templateId,
  }),

  [c.UPDATE_TEMPLATE]: (state, { template }) => ({
    ...state,
    templatesDict: { ...state.templatesDict, [template.id]: template },
  }),

  [c.UPDATE_FILE]: (state, { file }) => ({
    ...state,
    filesDict: { ...state.filesDict, [file.id]: file },
  }),
});

export default (state: ReduxState, action: AnyAction) => {
  const { type } = action;
  for (let i = 0; i < reducers.length; i++) {
    const reducer = reducers[i];
    if (reducer[type]) return reducer[type](state, action);
  }
  return state;
};
