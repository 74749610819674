import { ApiFile, ApiTemplate } from "src/types";

export type ReduxState = {
  files: string[],
  filesDict: { [fileId: string]: ApiFile },
  templates: string[],
  templatesDict: { [templateId: string]: ApiTemplate },
  selectedTemplate: number,
}

export default {
  files:            null,
  filesDict:        null,
  templates:        null,
  templatesDict:    null,
  selectedTemplate: null,
} as ReduxState;
