import * as React                                   from 'react';
import classnames                                   from 'classnames';
import { connect, ResolveThunks }                   from 'react-redux';
import _get                                         from 'lodash/get';
import { ApiProgressEvent, uploadFile, uploadLink } from "src/helpers/fetch";
import { addFile }                                  from "src/redux/actions";
import { ReduxState }                               from "src/redux/emptyState";
import styles                                       from './FileUpload.scss';

type OwnProps = {
  onUpload: (fileId: number) => any,
};
type StoreProps = ReturnType<typeof mapStateToProps>;
type StoreDispatch = typeof mapDispatchToProps;
type Props = OwnProps & StoreProps & ResolveThunks<StoreDispatch>;
type State = {
  uploadFileInProgress: boolean,
  uploadFileProgress: { width: string },
  uploadLinkInProgress: boolean,
};

class FileUpload extends React.PureComponent<Props, State> {
  state: State = {
    uploadFileInProgress: false,
    uploadFileProgress:   { width: '0' },
    uploadLinkInProgress: false,
  };

  componentDidMount() {
    const el = document.body;
    el.addEventListener('dragenter', this.handleDragIn);
    el.addEventListener('dragleave', this.handleDragOut);
    el.addEventListener('dragover', this.handleDrag);
    el.addEventListener('drop', this.handleDrop);
  }

  componentWillUnmount() {
    const el = document.body;
    el.removeEventListener('dragenter', this.handleDragIn);
    el.removeEventListener('dragleave', this.handleDragOut);
    el.removeEventListener('dragover', this.handleDrag);
    el.removeEventListener('drop', this.handleDrop);
  }

  handleDrag = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDragIn = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDragOut = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.onDropFile(e.dataTransfer.files);
      e.dataTransfer.clearData();
    }
  };

  onDropFile = (fileList: FileList) => {
    const file = fileList[0];
    if (this.state.uploadFileInProgress) return;
    const { addFile, onUpload } = this.props;
    if (!file) return;

    this.setState({ uploadFileInProgress: true, uploadFileProgress: { width: '0' } });
    uploadFile(file, this.onUploadProgress).then(
      (file) => {
        this.setState({ uploadFileInProgress: false, uploadFileProgress: { width: '0' } });
        addFile(file);
        onUpload && onUpload(file.id);
      }, (error) => {
        const message = _get(error, ['response', 'data', 'error']) || error;
        alert(message);
        this.setState({ uploadFileInProgress: false, uploadFileProgress: { width: '0' } });
      },
    );
  };

  onUploadProgress = (e: ApiProgressEvent) => { this.setState({ uploadFileProgress: { width: e.loaded / e.total * 100 + '%' } }); };

  onFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => this.onDropFile(e.target.files);

  onUploadLink = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const link = (e.target as any).link.value as string;
    if (!link) return;

    const { addFile, onUpload } = this.props;
    this.setState({ uploadLinkInProgress: true });
    uploadLink(link).then((file) => {
      addFile(file);
      onUpload && onUpload(file.id);
      // this.setState({ uploadLinkInProgress: false });
    }, (error) => {
      const message = _get(error, ['response', 'data', 'error']) || error;
      alert(message);
      this.setState({ uploadLinkInProgress: false });
    });
  };

  render() {
    const { uploadLinkInProgress, uploadFileInProgress, uploadFileProgress } = this.state;
    return (
      <div className={styles.upload}>
        <div className={styles.uploadBlock}>
          <div className={styles.uploadTitle}>Загрузить файл</div>
          <button className={`btn btn-success ${styles.btn}`}>
            <input type="file" onChange={this.onFileSelect} />
            <i className="fas fa-upload" /> Выбрать файл
          </button>
          <div className={styles.uploadOr}>или перетащите его в эту область</div>
          {uploadFileInProgress && (
            <div className={styles.progress}>
              <div className={classnames("progress", styles.bar)}>
                <div className="progress-bar progress-bar-striped progress-bar-animated" style={uploadFileProgress} />
              </div>
            </div>
          )}
        </div>
        <div className={styles.uploadBlock}>
          <div className={styles.uploadTitle}>Добавить файл по ссылке</div>
          <form onSubmit={this.onUploadLink}>
            <div className="input-group">
              <input name="link" type="text" className="form-control" placeholder="Ссылка на файл" />
              <div className="input-group-append">
                <button className="btn btn-success">Загрузить</button>
              </div>
            </div>
          </form>
          {uploadLinkInProgress && <div className={styles.progress}><i className="fas fa-spinner fa-spin" /></div>}
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state: ReduxState) => ({});
const mapDispatchToProps = { addFile };
export default connect<StoreProps, StoreDispatch, OwnProps>(mapStateToProps, mapDispatchToProps)(FileUpload);
