const isArrayData = (data: string[][] | Record<string, string>[]): data is string[][] => Array.isArray(data[0]);

export function generatePreviewTable(data: string[][] | Record<string, string>[]) {
  if (!data.length) return '';
  if (isArrayData(data)) {
    let table = '<table><tbody>';

    table += '<tr><td></td>';
    data[0].forEach((_, i) => table += `<td><b>${i}</b></td>`);
    table += '</tr>';

    data.forEach((row, i) => {
      table += `<tr><td><b>${i}</b></td>`;
      row.forEach(cell => table += `<td>${cell}</td>`);
      table += '</tr>';
    });
    table += '</tbody></table><style>table{border-spacing: 0px;border-collapse: collapse;}td{border: 1px solid #000;padding: 2px;}</style>';
    return table;
  } else {
    let result = '<pre>';
    data.forEach(row => {
      result += JSON.stringify(row, null, 2);
    });
    result += '</pre>';
    return result;
  }
}
