import { createStore, compose }                 from 'redux';
import { updateFilesList, updateTemplatesList } from "src/redux/actions";
import reducers                                 from 'src/redux/reducers';
import middlewares                              from 'src/redux/middlewares';
import emptyState                               from 'src/redux/emptyState';

const globalDevtool = (window as any).__REDUX_DEVTOOLS_EXTENSION__;

const enhancers = globalDevtool
  ? compose(middlewares, globalDevtool())
  : middlewares;

export default () => {
  const store = createStore(reducers, { ...emptyState }, enhancers);
  const { dispatch } = store;

  dispatch(updateFilesList());
  dispatch(updateTemplatesList());

  return store;
};
