import * as React from 'react';
import { Link } from 'react-router-dom';
import { connect, ResolveThunks } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TemplateName from 'src/components/TemplateName';
import FilesList from '../FilesList';
import { ReduxState } from 'src/redux/emptyState';
import styles from 'src/components/Header/Header.scss';
import { openModal } from '@elsa-esa/lib/components/Modals/helpers/modalOpener';
import TestTemplatePopup from 'src/components/TestTemplatePopup';

const getFileLink = (templateId: number) => `${document.location.origin}/api/templates/${templateId}.csv`;

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type StoreDispatch = typeof mapDispatchToProps;
type Props = OwnProps & StoreProps & ResolveThunks<StoreDispatch>;
type State = {};

class Header extends React.PureComponent<Props, State> {
  onShowFilesList = () => {
    openModal({
      size: 1024,
      renderBody: (props) => <FilesList {...props} />,
    });
  };

  onTest = () => {
    const { selectedTemplate } = this.props;
    openModal({
      // size: 1024,
      render: (props) => <TestTemplatePopup templateId={selectedTemplate}/>,
    });
  };

  onDownload = () => {
    const { selectedTemplate } = this.props;
    window.open(getFileLink(selectedTemplate));
  };

  // renderTemplateName() {
  //   const { template } = this.props;
  //   if (!template) return null;
  //   return (
  //     <div className={styles.templateName}>
  //       {template.name}{' '}
  //       <button className="btn btn-link btn-sm">
  //         <i className="far fa-edit" />
  //       </button>
  //     </div>
  //   );
  // }

  renderAside() {
    const { selectedTemplate } = this.props;
    const link = getFileLink(selectedTemplate);

    return (
      <React.Fragment>
        <input type="text" className={`form-control form-control-sm ${styles.input}`} disabled value={link}/>
        <CopyToClipboard text={link} onCopy={() => this.setState({ copied: true })}>
          <button className="btn btn-primary btn-sm ml-2"><i className="fas fa-copy"/> Скопировать ссылку</button>
        </CopyToClipboard>
        <button className="btn btn-info btn-sm ml-2" onClick={this.onTest}>
          <i className="fas fa-upload"/> Протестировать
        </button>
        <button className="btn btn-success btn-sm ml-2" onClick={this.onDownload}>
          <i className="fas fa-download"/> Скачать
        </button>
      </React.Fragment>
    );
  }

  render() {
    const { selectedTemplate } = this.props;

    return (
      <div className={styles.wrapper}>
        {selectedTemplate && (
          <Link className={`btn btn-outline-light btn-sm mr-3`} to="/">
            <i className="fas fa-chevron-left"/> К выбору шаблона
          </Link>
        )}
        <button className={`btn btn-primary btn-sm`} onClick={this.onShowFilesList}>
          Файлы
        </button>
        {selectedTemplate && <TemplateName templateId={selectedTemplate}/>}
        {selectedTemplate && this.renderAside()}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({ selectedTemplate: state.selectedTemplate });
const mapDispatchToProps = {};
export default connect<StoreProps, StoreDispatch, OwnProps>(mapStateToProps, mapDispatchToProps)(Header);
