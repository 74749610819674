import 'destyle.css';
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import './style.scss';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './components/App/App';

const root = createRoot(document.getElementById('app'));
root.render(<App/>);
